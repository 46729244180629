import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Trends and predictions from industry experts`}</h2>
    <p>{`The conversational interface space continues to evolve.`}</p>
    <p>{`When `}<a parentName="p" {...{
        "href": "https://www.dashbot.io"
      }}>{`we`}</a>{` first launched nearly four years ago, Facebook chatbots were all the rage – enterprises, startups, and developers were all exploring what they could do. Overtime, we saw many of these same folks shift to voice assistants as Alexa and Google Home became more prevalent. More recently, we have seen a strong uptick in web-based chatbots for customer service.`}</p>
    <p>{`All the while, the underlying technologies have continued to evolve. Speech to text, Natural Language Processing (NLP), and AI have improved significantly. While we are still relatively early, conversational interfaces have come a long way in a relatively short time period.`}</p>
    <p>{`In addition to sharing our own thoughts, we asked leading experts in the chatbot and voice assistant space, their thoughts on the biggest trends of 2019 as well as predictions for 2020.`}</p>
    <p>{`Our contributors include:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.linkedin.com/in/aweidauer/"
        }}>{`Alex Weidauer`}</a>{`, Co-founder `}{`&`}{` CEO, `}<a parentName="li" {...{
          "href": "https://rasa.com/"
        }}>{`Rasa`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.linkedin.com/in/angelafuentesnovosel/"
        }}>{`Angela Fuentes Novosel`}</a>{`, Sr Mgr Strategic Partnerships `}{`&`}{` Emerging Products, `}<a parentName="li" {...{
          "href": "https://www.tdameritrade.com/"
        }}>{`TD Ameritrade`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.linkedin.com/in/brandonskaplan/"
        }}>{`Brandon Kaplan`}</a>{`, Founder `}{`&`}{` CEO, `}<a parentName="li" {...{
          "href": "https://www.skilledcreative.com/"
        }}>{`Skilled Creative`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.linkedin.com/in/casey-phillips-mba-pmp/"
        }}>{`Casey Phillips`}</a>{`, Sr Product Manager Conversational AI Platform, `}<a parentName="li" {...{
          "href": "https://www.intuit.com/"
        }}>{`Intuit`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.linkedin.com/in/cpearl/"
        }}>{`Cathy Pearl`}</a>{`, Head of Conversation Design Outreach, `}<a parentName="li" {...{
          "href": "https://assistant.google.com/"
        }}>{`Google Assistant`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.linkedin.com/in/derekroberti/"
        }}>{`Derek Roberti`}</a>{`, VP Technology, North America, `}<a parentName="li" {...{
          "href": "https://cognigy.com/"
        }}>{`Cognigy`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.linkedin.com/in/scpassos/"
        }}>{`Sergio Passos`}</a>{`, CTO, `}<a parentName="li" {...{
          "href": "https://take.net/"
        }}>{`Take`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.linkedin.com/in/tim-carlson/"
        }}>{`Tim Carlson`}</a>{`, Senior Director `}{`&`}{` AI Product Manager, `}<a parentName="li" {...{
          "href": "https://www.travelers.com/"
        }}>{`Travelers Insurance`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.linkedin.com/in/trishalapillai/"
        }}>{`Trishala Pillai`}</a>{`, Partnerships Lead Voice `}{`&`}{` AI, `}<a parentName="li" {...{
          "href": "https://myplanet.com/"
        }}>{`Myplanet`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.linkedin.com/in/showpony/"
        }}>{`Will Hall`}</a>{`, Chief Creative Officer, `}<a parentName="li" {...{
          "href": "https://rain.agency/"
        }}>{`RAIN`}</a></li>
    </ul>
    <h2>{`Trends of 2019`}</h2>
    <p>{`In regards to the biggest trends of 2019, four common themes emerged from our panel of experts – the use of context, advancements in the ecosystem, expanded reach, and the increased adoption by enterprises.`}</p>
    <h3>{`Context is key`}</h3>
    <p>{`Context is important. With conversational interfaces, users tell you what they want and what they think of your chatbot or voice assistant. Making use of what you know about the user, what they have already said, where they currently are, and what they are doing can all be used to deliver a much better user experience.`}</p>
    <p>{`Will Hall of RAIN, points to the Apple Card as a “high-water mark” in contextually aware interactions – how SMS, Siri, Apple Wallet, and users all work together in a frictionless, unified system.`}</p>
    <p>{`Angela Fuentes Novosel of TD Ameritrade also added contextual and more personal experiences as some of the biggest trends of 2019. These are quite important in banking and financial interfaces too. Casey, of Intuit, echoed the increase in more contextualized conversations based on user data and history.`}</p>
    <h3>{`The ecosystem continues to advance`}</h3>
    <p>{`As conversational interfaces continue to take off, so have the underlying infrastructure tools and ecosystem.`}</p>
    <p>{`The richer ecosystem enables companies to more easily build better user experiences. As Tim Carlson of Travelers, points out, the strong advancements in NLP allows companies to spend more time focusing on user experience versus the initial understanding of the customers’ needs.`}</p>
    <p>{`Trishala Pillai of Myplanet adds that the breath of partners that augment each other’s capabilities across analytics, usability testing, security, and more, enable companies to more effectively design, develop, and optimize a voice experience. One could easily be working with two or more partners instead of having to build everything oneself. The underlying platforms are offering starter kits to speed development too, like Alexa’s `}<a parentName="p" {...{
        "href": "https://blueprints.amazon.com/"
      }}>{`Blue Prints`}</a>{` and Google’s `}<a parentName="p" {...{
        "href": "https://developers.google.com/actions/verticals/overview"
      }}>{`Vertical Solutions`}</a>{`.`}</p>
    <p>{`Brandon Kaplan of Skilled Creative sees similar advanced integrations into underlying data sources including CRMs and CMS to be some of the biggest trends of the past year.`}</p>
    <h3>{`Conversational interfaces continue to expand`}</h3>
    <p>{`The prevalence of conversational interfaces continues to grow. More and more devices and existing interfaces are adding conversational components.`}</p>
    <p>{`The integration of voice assistants, like Alexa and Google, into more products continues to expand. Cathy Pearl of Google pointed out the increase in “voice-forward,” smart devices as one of the biggest trends. We saw this throughout the year as voice assistants were added to cars, mirrors, microwaves, and so much more. Not only have there been more integrations, but as Trishala points out, the wide availability of the devices in the US, and the range available at any price point are some of the more common trends.`}</p>
    <p>{`Sergio Passos of Take, saw the adoption of WhatsApp in emerging markets like Brazil, as one of the biggest trends. Businesses were finally able to integrate their brands into one of the most used messaging platforms.`}</p>
    <p>{`Similarly, Derek Roberti of Cognigy, also saw chat integrations into more platforms, like mobile, as one of the bigger trends.`}</p>
    <h3>{`Enterprises are investing in the space`}</h3>
    <p>{`Enterprise adoption of conversational interfaces continues to rise.`}</p>
    <p>{`Whereas a few years ago, having an innovation team build a Facebook chatbot or an Alexa skill may have been more the norm, we are starting to see enterprises build out product teams focused on conversational interfaces. Alex Weidauer of Rasa saw this as one of the biggest trends of 2019.`}</p>
    <p>{`Trishala also saw a similar trend – that 2019 was the year organizations understood that conversational interfaces are here to stay, and that they are “critical to incorporate into employee and customer digital experiences.”`}</p>
    <p>{`We see something very similar – enterprises investing significant resources into building conversational interface teams. Whereas early on, and enterprise may have had only a few people viewing the analytics, we have a number of clients with an order of magnitude greater – 10, 20, 30, or more users accessing and viewing the analytics.`}</p>
    <h2>{`The future of chatbots and voice assistants`}</h2>
    <p>{`In addition to the trends of 2019, we asked our panel what areas need to improve in conversational interfaces and what their predictions are for this coming year.`}</p>
    <p>{`Our panelists were all fairly in agreement on where improvements needed to be made in the conversational interface space. The most common areas are better AI and NLP, increased monetization opportunities, and improvements in user acquisition and discovery.`}</p>
    <p>{`In regards to predictions for 2020, our panel envisions more strategic enterprise initiatives, proactive experiences, and better relationships between users and conversational interfaces.`}</p>
    <h3>{`From pilot to production`}</h3>
    <p>{`Continuing on the trend our panelists saw in 2019 of enterprises investing more in conversational interfaces, the most common prediction our panel made is for enterprises to invest even more, and take the initiatives from experimentation to strategic deployments.`}</p>
    <p>{`As Derek predicts, conversational interfaces will move closer to mainstream as enterprises integrate them into their omnichannel customer initiatives. He sees organizations moving from experimentation to deployments that fit within their budget cycles and KPI measurements. Brandon sees a similar evolution to larger programs rather than just pilots. Tim also sees enterprises broadening the functionality of their chatbots.`}</p>
    <p>{`Alex adds more teams will bring machine learning in house to improve the accuracy and create a competitive advantage through better training data.`}</p>
    <p>{`It is not just enterprises investing more, but investing in the right use cases. Cathy is hopeful we will see more brands figuring out the strongest use cases for conversational interfaces. Trishala sees companies learning from their earlier pilots to “enhance their perspective on what good use cases for voice will actually look like.” She adds that the level of complexity of use cases will continue to increase, and companies will better understand voice is different and not to try to replicate web and mobile experiences.`}</p>
    <p>{`Will adds that having a robust conversational strategy will be “table stakes.” As a more specific use case, he sees the financial industry continuing to “double down” on conversational interfaces – with the adoption of Bank of America’s Erica chatbot and the traction of Apple’s credit card as prime examples.`}</p>
    <h3>{`Improved interactions`}</h3>
    <p>{`Our panelists see that not only will enterprises invest more into conversational interfaces, but the relationships they are building with end users will improve as well.`}</p>
    <p>{`Sergio predicts brands will build consumer trust in conversational interfaces to enable long term relationships. Angela adds that there is an opportunity for intelligent agents to take over the more advisory part of the conversation, thus deepening how brands build relationships with their clients.`}</p>
    <p>{`Trishala adds that we will see more effective and efficient conversational user experiences, as organizations build on their understanding of these interfaces.`}</p>
    <h3>{`Predictive interactions`}</h3>
    <p>{`As the NLP and AI improve, our panelists see an opportunity for more proactive interactions.`}</p>
    <p>{`Casey predicts chatbots will start to become more predictive and suggest to users what their issue is, rather than waiting for the user to describe the issue. This is one of the areas they are working on at Intuit. It also ties in nicely with the increased use of context – knowing more about the user, their history, and context, can help with being more predictive.`}</p>
    <p>{`Tim seconds this prediction in proactive interactions. He cites Capital One’s Eno as an example – an assistant that can automatically send account insights and alerts.`}</p>
    <p>{`Having processed over 80 billion messages in conversational interfaces, this is also an area we are also pretty excited about – learning from usage behavior to proactively recommend responses or interactions.`}</p>
    <h2>{`Industries and use cases to watch`}</h2>
    <p>{`Lastly, we also polled our panelists as to which use cases will become more common in 2020 and which industries will make the biggest strides in conversational interfaces. The most common use cases our panelists predicted are in customer service and commerce, followed by general knowledge sharing. Similarly, our panel predicted the industries to watch are in customer service and banking/finance as well, as the most common.`}</p>
    <h2>{`The future`}</h2>
    <p>{`Much like our panelists, we are excited about the future of conversational interfaces and look forward to seeing what enterprises, start ups, and ecosystem partners build in the coming year.`}</p>
    <h3>{`About Dashbot`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io"
      }}>{`Dashbot`}</a>{` is an analytics platform for conversational interfaces that enables enterprises to increase engagement, satisfaction, and conversion through actionable insights and tools.`}</p>
    <p>{`In addition to traditional metrics like engagement and retention, we provide chatbot specific analytics including NLP response effectiveness, sentiment analysis, conversation flows, and the full chat session transcripts.`}</p>
    <p>{`We also have tools to take action on the data, like our Live Person Take Over of chat sessions and push notifications for re-engagement.`}</p>
    <p>{`We support Alexa, Google Assistant, Web Chat, SMS, Facebook Messenger, Slack, Twitter, Bixby, and any other conversational interface.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      